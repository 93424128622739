
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n";
import moment from "moment";

interface Attendance {
  date: string;
  createdAt: string;
  type: string;
  updatedAt: string;
}

export default defineComponent({
  name: "Student-Attendance-Statistic",
  props: {
    title: String,
    description: String,
    data: Object,
    height: Number,
  },
  components: {},
  setup(props) {
    const { t } = useI18n();

    const attendance = props?.data as Attendance[];
    const currentDate = new Date();
    const today = new Date().getDate();
    const monthName = moment().format("MMMM");

    const currentMonthAttendance = attendance.filter((att: Attendance) => {
      const attDate = new Date(att.date);
      return (
        attDate.getMonth() == currentDate.getMonth() &&
        attDate.getFullYear() == currentDate.getFullYear() &&
        attDate.getDate() <= today
      );
    });

    const days: string[] = [];
    for (let i = 1; i <= today; i++) days.push(i + " " + monthName);

    const absentSerie: number[] = [];
    const delaySerie: number[] = [];
    for (let i = 1; i <= today; i++) {
      let a = 0;
      let d = 0;
      for (const att of currentMonthAttendance) {
        if (new Date(att.date).getDate() == i)
          if (att.type == "Retard") d++;
          else a++;
      }
      absentSerie.push(a);
      delaySerie.push(d);
    }

    const series = [
      {
        name: t("attendance.delays"),
        data: delaySerie,
      },
      {
        name: t("attendance.absents"),
        data: absentSerie,
      },
    ];

    const labelColor = getCSSVariableValue("--bs-gray-800");
    const warningColor = getCSSVariableValue("--bs-warning");
    const lightWarning = getCSSVariableValue("--bs-light-warning");
    const dangerColor = getCSSVariableValue("--bs-danger");
    const lightDanger = getCSSVariableValue("--bs-light-danger");

    const options = {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [warningColor, dangerColor],
      },
      xaxis: {
        type: "string",
        categories: days,
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: [
        {
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
      ],
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          stops: [0, 100],
        },
      },
      colors: [warningColor, dangerColor],
      markers: {
        colors: [warningColor, dangerColor],
        strokeColor: [lightWarning, lightDanger],
        strokeWidth: 3,
      },
    };
    return {
      options,
      series,
    };
  },
});
